import mixpanel from 'mixpanel-browser'

export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const runtimeConfig = useRuntimeConfig()

    const { env, mixpanelToken } = runtimeConfig.public

    mixpanel.init(mixpanelToken, {
      debug: env !== 'prod',
      ignore_dnt: true
    })

    return {
      provide: {
        mixpanel
      }
    }
  }
})
